<template>
  <div class="bg-white py-2 rounded-xl" id="ElectronicMedicalRecords">
    <div>
      <div class="flex h-full">
        <MenuTreeView />
        <div class="w-full h-detail px-3 overflow-y-scroll relative">
          <TreatmentCard
            v-show="tab === 'master'"
            :medicalRecordMain="medicalRecordMain"
            :medical_record_code="medical_record_code"
            :treat_doctor="treat_doctor"
            :getBADT="getBADT"
            @refresh="getDetailBADT"
          />
          <EmrIndicationList
            :mr_id="mr_id"
            v-if="
              !hasVimesSync &&
              (tab === 'CLS' || tab === 'ChanDoanHinhAnh') &&
              showTab
            "
            :indType="tab === 'CLS' ? 1 : 2"
          />
          <EmrIndicationListVimes
            :mr_id="mr_id"
            v-if="
              hasVimesSync &&
              (tab === 'CLS' || tab === 'ChanDoanHinhAnh') &&
              showTab
            "
            :indType="tab === 'CLS' ? 1 : 2"
          />

          <DanhSachBienBanHoiChan
            :mr_id="mr_id"
            v-if="tab === 'BienBanHoiChan'"
          />
          <FormTTTQM :mr_id="mr_id" v-if="tab === 'TTTQM'" />
          <!-- <ChanDoanHinhAnh v-if="tab === 'ChanDoanHinhAnh'" /> -->
          <PhieuSoKetList v-if="tab === 'PhieuSoKet'" />
          <TreatmentList v-if="tab === 'PhieuDieuTri'" />
          <PhieuChamSocList v-if="tab === 'PhieuChamSoc'" />
          <PhieuYLenhTruyenList v-if="tab === 'PhieuYLenhTruyen'" />
          <PhieuDanhGiaDinhDuongList v-if="tab === 'DanhGiaDinhDuong'" />
          <EmrVitalsignList :mr_id="mr_id" v-if="tab === 'Vitalsign'" />
          <PhieuPhauThuatList v-if="tab === 'PhieuPhauThuat'" />
          <PhieuKhamGayMeTruocMoList v-if="tab === 'PhieuKhamGayMeTruocMo'" />
          <PhieuGayMeHoiSucList v-if="tab === 'PhieuGayMeHoiSuc'" />
          <DichVuYTeList v-if="tab === 'DichVuYTe'" />
          <ChuyenTuyen v-if="tab === 'ChuyenTuyen'" />
          <GiayHenKhamLai v-if="tab === 'GiayHenKhamLai'" />
          <GiayChungNhan v-if="tab === 'GiayChungNhan'" />
          <ListBMHCForDisease v-if="tab === 'BMHC' && forDisease" />
          <!-- <GiayCamKet v-if="tab === 'GiayCamKet'" /> -->
          <GiayXacNhanDieuTri v-if="tab === 'GiayXacNhanDieuTri'" />
          <GiayXacNhanDieuTri v-if="tab === 'GiayChungNhanPhauThuat'" />
          <GiayDeNghiThanhToan v-if="tab === 'GiayDeNghiThanhToan'" />

          <ListBMHC v-if="tab === 'BMHC' && !forDisease" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { IconSearch } from '@/components/Icon'
import { ButtonHoDo } from '@/components/Button'
import TreatmentCard from '@/components/ElectronicMedicalRecords/CardMaster/TreatmentCard'
import TreatmentList from '@/components/ElectronicMedicalRecords/Treatment/List'
import PhieuChamSocList from '@/components/ElectronicMedicalRecords/PhieuChamSoc/List'
import PhieuYLenhTruyenList from '@/components/ElectronicMedicalRecords/PhieuYLenhTruyen/List'
import PhieuDanhGiaDinhDuongList from '@/components/ElectronicMedicalRecords/PhieuDinhDuong/List'
import ChanDoanHinhAnh from '@/components/ElectronicMedicalRecords/ChanDoanHinhAnh'
import EmrIndicationList from '@/pages/ElectronicMedicalRecords/components/EmrIndications/EmrIndicationList.vue'
import EmrIndicationListVimes from '@/pages/ElectronicMedicalRecords/components/EmrIndications/EmrIndicationListVimes.vue'
import EmrVitalsignList from '@/pages/ElectronicMedicalRecords/components/EmrVitalsigns/EmrVitalsignList'
import DanhSachBienBanHoiChan from '@/pages/ElectronicMedicalRecords/components/HoiChan/ListHoiChan'
import FormTTTQM from '@/pages/ElectronicMedicalRecords/components/HoiChan/ListTQM'
import PhieuSoKetList from '@/components/ElectronicMedicalRecords/PhieuSoKet/PhieuSoKetList.vue'
import PhieuPhauThuatList from '@/components/ElectronicMedicalRecords/PhieuPhauThuat/List'
import PhieuKhamGayMeTruocMoList from '@/components/ElectronicMedicalRecords/PhieuGayMeTruocMo/List'
import PhieuGayMeHoiSucList from '@/components/ElectronicMedicalRecords/PhieuGayMeHoiSuc/List'
import DichVuYTeList from '@/components/ElectronicMedicalRecords/DichVuYTe/List'

import ChuyenTuyen from '@/components/ElectronicMedicalRecords/ChuyenTuyen/List'
import GiayHenKhamLai from '@/components/ElectronicMedicalRecords/GiayHenKhamLai/List'
import GiayChungNhan from '@/components/ElectronicMedicalRecords/GiayChungNhan/List'
import GiayCamKet from '@/components/ElectronicMedicalRecords/GiayCamKet/List'
import GiayXacNhanDieuTri from '@/components/ElectronicMedicalRecords/GiayXacNhanDieuTri/List'
import GiayDeNghiThanhToan from '@/components/ElectronicMedicalRecords/GiayDeNghiThanhToan/List'

import ListBMHC from '@/components/BieuMauHanhChinh/ListBMHC.vue'
import ListBMHCForDisease from '../../../../components/BieuMauHanhChinh/ForDisease/ListBMHCForDisease.vue'
import { mixinHasFeatures } from '@/utils/mixinHasFeatures'

import { LIST_BMHC_DISEASE, BMHC_OPTIONS } from '@/utils/constants'

import MenuTreeView from './Menu.vue'
import _ from 'lodash'
import { mapState } from 'vuex'
import { APP_MODE } from '../../../../utils/constants'

const tablist = [
  'master',
  'CLS',
  'ChanDoanHinhAnh',
  'BienBanHoiChan',
  'PhieuSoKet',
  'TTTQM',
  'PhieuPhauThuat',
  'PhieuKhamGayMeTruocMo',
  'PhieuGayMeHoiSuc',
  'PhieuDieuTri',
  'PhieuChamSoc',
  'PhieuYLenhTruyen',
  'Vitalsign',
  'DanhGiaDinhDuong',
  'DichVuYTe',
  'ChuyenTuyen',
  'GiayHenKhamLai',
  'GiayChungNhan',
  'GiayCamKet',
  'GiayXacNhanDieuTri',
  'GiayChungNhanPhauThuat',
  'BMHC',
  'GiayDeNghiThanhToan'
]
export default {
  name: 'TreeView',
  components: {
    MenuTreeView,
    IconSearch,
    ButtonHoDo,
    TreatmentCard,
    TreatmentList,
    DanhSachBienBanHoiChan,
    FormTTTQM,
    PhieuChamSocList,
    PhieuYLenhTruyenList,
    PhieuDanhGiaDinhDuongList,
    ChanDoanHinhAnh,
    EmrIndicationList,
    EmrIndicationListVimes,
    PhieuSoKetList,
    PhieuPhauThuatList,
    PhieuKhamGayMeTruocMoList,
    EmrVitalsignList,
    PhieuGayMeHoiSucList,
    DichVuYTeList,
    ChuyenTuyen,
    GiayHenKhamLai,
    GiayChungNhan,
    GiayCamKet,
    GiayXacNhanDieuTri,
    ListBMHC,
    ListBMHCForDisease,
    GiayDeNghiThanhToan
  },
  mixins: [mixinHasFeatures],
  data () {
    return {
      searchValue: '',
      tab: 'master',
      extraSidebar: true,
      medicalRecordMain: null,
      idMedicalRecords: null,
      medical_record_code: null,
      treat_doctor: null,
      getBADT: [],
      mounting: false,
      tablist,
      mr_id: null,
      showTab: true,
      bmhcType: null,
      BMHC_OPTIONS,
      LIST_BMHC_DISEASE
    }
  },
  created () {
    if (
      _.isEmpty(this.$featureList) &&
      _.isEmpty(this.$featureWsList) &&
      _.isEmpty(this.$featureOrgList)
    ) {
      this.mounting = true
      setTimeout(() => {
        this.mounting = false
      }, 5000)
    } else {
      this.mounting = false
    }
  },
  computed: {
    ...mapState({
      personStore: (state) => state.person
    }),
    hasBadt () {
      return (
        (this.$featureList && this.$featureList['badt']) ||
        (this.$appMode === APP_MODE.workspace
          ? this.$featureWsList && this.$featureWsList['badt']
          : this.$appMode === APP_MODE.organization
            ? this.$featureOrgList && this.$featureOrgList['badt']
            : false) ||
        false
      )
    },
    forDisease () {
      return this.LIST_BMHC_DISEASE.some(
        (item) => item === Number(this.$route.query.bmhc)
      )
    }
  },
  watch: {
    $featureList (list) {
      if (!_.isEmpty(list)) {
        this.mounting = false
      }
    },
    $featureWsList (list) {
      if (!_.isEmpty(list)) {
        this.mounting = false
      }
    },
    $featureOrgList (list) {
      if (!_.isEmpty(list)) {
        this.mounting = false
      }
    },
    '$route.query.tab' () {
      this.handleSelected()
      this.showTab = false
      setTimeout(() => {
        this.showTab = true
      }, 100)
    },
    '$route.query.bmhc' () {
      window.scroll(0, 0)
    }
  },
  mounted () {
    this.handleSelected()

    this.getDetailBADT()

    this.mr_id = this.$route.params.id
  },
  methods: {
    handleSelected () {
      if (
        this.$route.query.tab &&
        this.tablist.indexOf(this.$route.query.tab) !== -1
      ) {
        if (this.$route.query.bmhc) {
          this.bmhcType = Number(this.$route.query.bmhc)
        }

        this.tab = this.$route.query.tab
      } else {
        this.bmhcType = null
        this.tab = 'master'
        this.$router.replace({ query: { tab: 'master' } })
      }
    },
    getDetail (show) {
      this.tab = show
      if (this.tab !== this.$route.query.tab) {
        this.$router.replace({ query: { tab: this.tab } })
      }
    },
    backListBADT () {
      this.$router.go(-1)
    },
    onOpenSidebar () {
      this.extraSidebar = !this.extraSidebar
    },
    async getDetailBADT (callback) {
      this.idMedicalRecords = this.$route.params.id
      if (!this.idMedicalRecords) {
        return
      }
      const res = await this.$rf
        .getRequest('DoctorRequest')
        .getDetailBADT(this.idMedicalRecords)

      this.getBADT = res.data
      const medicalRecordMasterResponse = await this.getMedicalRecordMaster(
        res.data?.medical_record_master?.id
      )

      this.medicalRecordMain = medicalRecordMasterResponse || {}
      this.medical_record_code = res.data?.medical_record_code
      this.treat_doctor = res.data?.treat_doctor
      const person = {
        ...res.data.person,
        medical_record_name: res.data.medical_record_name,
        treat_doctor: res.data.treat_doctor_name,
        main_doctor: res.data.main_doctor_name,
        medical_record_id: res.data.id,
        status: this.getBADT && this.getBADT.status,
        ref_id: res.data.ref_id,
        person_ref_id: res.data.person_ref_id,
        guardian: this.personStore?.guardian || {}
      }
      if (this.medicalRecordMain && this.medicalRecordMain.admission_room) {
        person.admission_room = this.medicalRecordMain.admission_room
      }
      if (this.medicalRecordMain && this.medicalRecordMain.admission_bed) {
        person.admission_bed = this.medicalRecordMain.admission_bed
      }
      //
      if (!this.medicalRecordMain?.career_name) {
        this.medicalRecordMain.career_name = person.career
      }
      if (!this.medicalRecordMain?.ethnic_name) {
        this.medicalRecordMain.ethnic_name = person.ethnic_name
      }
      if (!this.medicalRecordMain?.nationality_name) {
        this.medicalRecordMain.nationality_name = person.foreigner
      }
      if (!this.medicalRecordMain?.address_detail) {
        this.medicalRecordMain.address_detail = person.address
      }
      if (!this.medicalRecordMain?.address_wards_name) {
        this.medicalRecordMain.address_wards_name = person.address_wards_name
      }
      if (!this.medicalRecordMain?.address_district_name) {
        this.medicalRecordMain.address_district_name =
          person.address_district_name
      }
      if (!this.medicalRecordMain?.address_province_name) {
        this.medicalRecordMain.address_province_name =
          person.address_province_name
      }
      if (!this.medicalRecordMain?.work_at) {
        this.medicalRecordMain.work_at = person.work_at
      }
      if (!this.medicalRecordMain?.insurance_type) {
        this.medicalRecordMain.insurance_type = person.insurance_type
      }
      if (!this.medicalRecordMain?.insurance_expire) {
        this.medicalRecordMain.insurance_expire = person.insurance_expire
      }
      if (!this.medicalRecordMain?.insurance_number) {
        this.medicalRecordMain.insurance_number = person.insurance_number
      }
      if (!this.medicalRecordMain?.emergency_contact) {
        this.medicalRecordMain.emergency_contact = person.emergency_contact
      }
      //
      this.$store.commit('setPerson', { person })
      this.$store.commit('setMedicalRecordMain', {
        medicalRecordMain: this.medicalRecordMain
      })
      this.$store.commit('setEmrData', {
        emrData: this.getBADT
      })

      callback && callback()
    },
    getCheckHighlight (tab) {
      return (
        tab === 'CLS' || tab === 'BienBanHoiChan' || tab === 'PhieuDieuTri'
      )
    },
    async getMedicalRecordMaster (medicalRecordMasterID) {
      if (!medicalRecordMasterID) return

      try {
        const response = await this.$rf
          .getRequest('DoctorRequest')
          .getDetailMedicalRecordMaster(medicalRecordMasterID)

        return response.data
      } catch (error) {
        console.log(error)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.h-detail {
  height: calc(100vh - 14rem);
  // height: "100%";
}
</style>
