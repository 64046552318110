<template>
  <div class="min-h-full">
    <div @click="(isCollapse = !isCollapse)" class="cursor-pointer">
      <svg
        :class="!isCollapse ? 'fliped mb-2' : ''"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M20.9985 6.00001L7.99959 6.16709L8.02529 8.16693L21.0242 7.99985L20.9985 6.00001ZM21.0628 10.9996L11.0636 11.1281L11.0893 13.128L21.0885 12.9994L21.0628 10.9996ZM21.1527 17.999L21.127 15.9992L8.12811 16.1663L8.15381 18.1661L21.1527 17.999ZM3.03098 8.64115L6.65682 12.1848L3.12325 15.8206L4.55126 17.2123L9.48659 12.1485L4.42274 7.21315L3.03098 8.64115Z"
          fill="black"
        />
      </svg>
    </div>
    <el-menu
      :default-active="indexActive"
      class="el-menu-vertical-demo h-full"
      @select="handleOpen"
      :collapse="isCollapse"
    >
      <el-menu-item index="master">
        <span slot="title">Bệnh án Vào Viện</span>
      </el-menu-item>
      <el-menu-item index="CLS">
        <span slot="title">Xét Nghiệm/ CLS</span>
      </el-menu-item>
      <el-menu-item index="ChanDoanHinhAnh">
        <span slot="title">Chẩn đoán hình ảnh</span>
      </el-menu-item>
      <el-menu-item index="BienBanHoiChan">
        <span slot="title">Hội Chẩn</span>
      </el-menu-item>
      <el-menu-item index="PhieuSoKet">
        <span slot="title">Phiếu sơ kết điều trị</span>
      </el-menu-item>
      <el-menu-item index="TTTQM">
        <span slot="title">Thông qua mổ</span>
      </el-menu-item>
      <el-menu-item index="PhieuPhauThuat">
        <span slot="title">Phiếu phẫu thuật/ thủ thuật</span>
      </el-menu-item>
      <el-menu-item index="PhieuKhamGayMeTruocMo">
        <span slot="title">Phiếu khám gây mê trước mổ</span>
      </el-menu-item>
      <el-menu-item index="PhieuGayMeHoiSuc">
        <span slot="title">Phiếu gây mê hồi sức</span>
      </el-menu-item>
      <el-menu-item index="PhieuDieuTri">
        <span slot="title">Phiếu Điều trị</span>
      </el-menu-item>
      <el-menu-item index="PhieuChamSoc">
        <span slot="title">Phiếu chăm sóc</span>
      </el-menu-item>
      <el-menu-item index="PhieuYLenhTruyen">
        <span slot="title">Phiếu Y lệnh truyền</span>
      </el-menu-item>
      <el-menu-item index="Vitalsign">
        <span slot="title">Phiếu theo dõi chức năng sống</span>
      </el-menu-item>
      <el-menu-item index="DanhGiaDinhDuong">
        <span slot="title">Đánh giá dinh dưỡng</span>
      </el-menu-item>
      <el-menu-item index="DichVuYTe">
        <span slot="title">Phiếu công khai thuốc và dịch vụ y tế</span>
      </el-menu-item>
      <el-submenu index="PhieuHanhChinh">
        <template slot="title">
          <span slot="title">Biểu mẫu hành chính</span>
        </template>
        <el-menu-item-group>
          <el-menu-item index="ChuyenTuyen">1.Chuyển tuyến</el-menu-item>
          <el-menu-item index="GiayHenKhamLai">2.Giấy hẹn khám lại</el-menu-item>
          <el-menu-item class="cs-lh" index="GiayChungNhan">3.Giấy chứng nhận nghỉ việc hưởng BHXH</el-menu-item>
          <el-menu-item :index="'BMHC&?bmhc=15'">4.Giấy ra viện</el-menu-item>
          <el-menu-item
            class="cs-lh"
            :index="'BMHC&?bmhc=11'"
          >5.Giấy cam đoan chấp nhận điều trị hoá chất</el-menu-item>
          <el-menu-item index="GiayXacNhanDieuTri">6.Giấy xác nhận điều trị</el-menu-item>
          <el-menu-item
            :class="[3,7,8].includes(index) ? 'cs-lh' : ''"
            v-for="(tab,index) in bmhcList"
            :key="tab"
            :index="tab"
          >{{ getLabelBMHC(tab) }}</el-menu-item>
          <el-menu-item :index="'BMHC&?bmhc=16'">20.Giấy chứng nhận phẫu thuật</el-menu-item>
          <el-menu-item index="GiayDeNghiThanhToan">21.Giấy đề nghị thanh toán chi phí hỗ trợ cho BN</el-menu-item>
        </el-menu-item-group>
      </el-submenu>
    </el-menu>
  </div>
</template>


<script>
import { BMHC_OPTIONS } from '@/utils/constants'

export default {
  name: 'MenuTreeView',
  props: [],
  data () {
    return {
      isCollapse: false,
      current_route_name: '',
      BMHC_OPTIONS
    }
  },
  mounted () {
    this.current_route_name = this.$route.query.tab
  },
  watch: {
    '$route.query.tab' (value) {
      this.current_route_name = value
    }
  },
  computed: {
    bmhcList () {
      return Object.values(this.BMHC_OPTIONS).map(item => `BMHC&?bmhc=${item}`)
    },
    indexActive () {
      const home = ['master']
      const cls = ['CLS']
      const chanDoanHinhAnh = ['ChanDoanHinhAnh']
      const bienBanHoiChan = ['BienBanHoiChan']
      const phieuSoKet = ['PhieuSoKet']
      const tttqm = ['TTTQM']
      const phieuPhauThuat = ['PhieuPhauThuat']
      const phieuKhamGayMeTruocMo = ['PhieuKhamGayMeTruocMo']
      const phieuGayMeHoiSuc = ['PhieuGayMeHoiSuc']
      const phieuDieuTri = ['PhieuDieuTri']
      const phieuChamSoc = ['PhieuChamSoc']
      const phieuYLenhTruyen = ['PhieuYLenhTruyen']
      const danhGiaDinhDuong = ['DanhGiaDinhDuong']
      const phieuChucNang = ['Vitalsign']
      const dichVuYTe = ['DichVuYTe']
      const phieuHanhChinh = ['PhieuHanhChinh']
      const giayhenkhamlai = ['GiayHenKhamLai']
      const chuyentuyen = ['ChuyenTuyen']
      const giaychungnhan = ['GiayChungNhan']
      const giayravien = ['GiayRaVien']
      const giaycamketdieutrihoachat = ['GiayCamKetDieuTriHoaChat']
      const giayxacnhandieutri = ['GiayXacNhanDieuTri']
      const giaychungnhanphauthuat = ['GiayChungNhanPhauThuat']
      const giayDeNghiThanhToan = ['GiayDeNghiThanhToan']

      const bmhc = ['BMHC']

      if (this.isRouteNames(home)) return 'master'
      if (this.isRouteNames(cls)) return 'CLS'
      // if (this.isRouteNames(cls2)) return '2-1'
      if (this.isRouteNames(chanDoanHinhAnh)) return 'ChanDoanHinhAnh'
      if (this.isRouteNames(bienBanHoiChan)) return 'BienBanHoiChan'
      if (this.isRouteNames(phieuSoKet)) return 'PhieuSoKet'
      if (this.isRouteNames(tttqm)) return 'TTTQM'
      if (this.isRouteNames(phieuPhauThuat)) return 'PhieuPhauThuat'
      if (this.isRouteNames(phieuKhamGayMeTruocMo)) {
        return 'PhieuKhamGayMeTruocMo'
      }
      if (this.isRouteNames(phieuGayMeHoiSuc)) return 'PhieuGayMeHoiSuc'
      if (this.isRouteNames(phieuDieuTri)) return 'PhieuDieuTri'
      if (this.isRouteNames(phieuChamSoc)) return 'PhieuChamSoc'
      if (this.isRouteNames(phieuYLenhTruyen)) return 'PhieuYLenhTruyen'
      if (this.isRouteNames(phieuChucNang)) return 'Vitalsign'
      if (this.isRouteNames(danhGiaDinhDuong)) return 'DanhGiaDinhDuong'
      if (this.isRouteNames(dichVuYTe)) return 'DichVuYTe'
      if (this.isRouteNames(phieuHanhChinh)) return 'PhieuHanhChinh'
      if (this.isRouteNames(giayhenkhamlai)) return 'GiayHenKhamLai'
      if (this.isRouteNames(chuyentuyen)) return 'ChuyenTuyen'
      if (this.isRouteNames(giaychungnhan)) return 'GiayChungNhan'
      if (this.isRouteNames(giayravien)) return 'GiayRaVien'
      if (this.isRouteNames(giaycamketdieutrihoachat)) return 'BMHC&?bmhc=11'
      if (this.isRouteNames(giayxacnhandieutri)) return 'GiayXacNhanDieuTri'
      if (this.isRouteNames(giaychungnhanphauthuat)) {
        return 'BMHC&?bmhc=15'
      }
      if (this.isRouteNames(giayDeNghiThanhToan)) {
        return 'GiayDeNghiThanhToan'
      }

      if (this.isRouteNames(bmhc)) {
        return `BMHC&?bmhc=${this.$route.query.bmhc}`
      }

      return 'master'
    }
  },
  methods: {
    handleOpen (key) {
      const type = Number(key.slice(key.indexOf('=') + 1, key.length))
      if (type > 0 && type !== this.$route.query.bmhc) {
        this.$router.replace({ query: { tab: 'BMHC', bmhc: type } })
        return
      }
      if (key !== this.$route.query.tab) {
        this.$router.replace({ query: { tab: key } })
      }
    },
    isRouteNames (routeNames = []) {
      return routeNames.includes(this.current_route_name)
    },
    getLabelBMHC (data) {
      const type = Number(data.slice(data.indexOf('=') + 1, data.length))

      if (type === this.BMHC_OPTIONS.xnHIV) {
        return '7.Giấy cam kết HIV, HBV, HCV'
      }
      if (type === this.BMHC_OPTIONS.giayXinRaVien) return '8.Đơn xin ra viện'
      if (type === this.BMHC_OPTIONS.dungCuYTe) {
        return '9.Đơn xin sử dụng dịch vụ theo yêu cầu'
      }
      if (type === this.BMHC_OPTIONS.kiThuatXN) {
        return '10.Giấy cam đoan thực hiện xét nghiệm'
      }
      if (type === this.BMHC_OPTIONS.camKetPTTT) {
        return '11.Giấy cam đoan PT- TT'
      }
      if (type === this.BMHC_OPTIONS.dungCuPhauThuat) {
        return '12.Đơn xin sử dụng dụng cụ PT TYC'
      }
      if (type === this.BMHC_OPTIONS.khamTimMach) {
        return '13.Đơn xin tự nguyện khám tim mạch'
      }
      if (type === this.BMHC_OPTIONS.dieuTriTuNguyen) {
        return '14.Đơn xin nằm giường điều trị tự nguyện'
      }
      if (type === this.BMHC_OPTIONS.suDungThuoc) {
        return '15.Đơn xin sử dụng thuốc đồng _ngoài chi trả BHYT'
      }
      if (type === this.BMHC_OPTIONS.tienSuNguoiBenh) {
        return '16.Giấy cam kết chụp MRI'
      }
      if (type === this.BMHC_OPTIONS.donXinSaoLuuBenhAn) {
        return '17.Đơn xin sao lưu hồ sơ bệnh án'
      }
      if (type === this.BMHC_OPTIONS.dieuTriBangMayGiaToc) {
        return '18.Đơn xin điều trị bằng máy gia tốc '
      }
      if (type === this.BMHC_OPTIONS.chapNhanXaTri) {
        return '19.Giấy cam đoan chấp nhận xạ trị'
      }
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep {
  .el-menu-vertical-demo:not(.el-menu--collapse) {
    width: 280px;
    height: 100%;
  }
  .el-menu--collapse {
    width: 48px;
  }
  .el-menu-item,
  .el-submenu__title,
  .el-submenu .el-menu-item {
    height: fit-content;
    line-height: 32px;
    padding: 0 10px !important;
    white-space: pre-wrap;
  }

  .el-menu-item.is-active {
    background-color: #20409b !important;
  }
  .el-menu-item.is-active {
    color: white !important;
  }
  .el-menu-item:focus,
  .el-menu-item:hover {
    background-color: #e4e7ec;
  }
  .fliped {
    -webkit-transform: scaleX(-1);
    transform: scaleX(-1);
  }
  .el-menu-item-group__title {
    padding: 0;
    height: 0;
  }
  .cs-lh {
    line-height: 28px !important;
  }
}
</style>
