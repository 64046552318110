<template>
  <div class="bg-white mx-2 my-2 rounded-xl">
    <div v-show="!mounting && hasBadt">
      <div class="w-full px-3 my-3 h-full">
        <HeaderEMR />
      </div>
    </div>
    <div
      v-if="!mounting && !hasBadt"
      class="d-flex align-items-center justify-content-center py-5"
    >
      <p class="fs-16 fw-500 text-center">
        Chức này không khả dụng với Workspace hiện tại!
      </p>
    </div>
    <div
      v-if="mounting"
      class="d-flex align-items-center justify-content-center py-5"
    >
      <div class="spinner-border" role="status">
        <span class="sr-only">Loading...</span>
      </div>
    </div>
  </div>
</template>

<script>
import { APP_MODE } from '../../utils/constants'
import HeaderEMR from './components/Header'
import _ from 'lodash'

const tablist = [
  'master',
  'CLS',
  'ChanDoanHinhAnh',
  'BienBanHoiChan',
  'PhieuSoKet',
  'TTTQM',
  'PhieuPhauThuat',
  'PhieuKhamGayMeTruocMo',
  'PhieuGayMeHoiSuc',
  'PhieuDieuTri',
  'PhieuChamSoc',
  'Vitalsign',
  'DanhGiaDinhDuong',
  'DichVuYTe',
  'PhieuHanhChinh'
]
export default {
  name: 'ElectronicMedicalRecords',
  components: { HeaderEMR },
  data () {
    return {
      searchValue: '',
      tab: 'master',
      extraSidebar: true,
      medicalRecordList: [
        {
          name: 'Bệnh án Ung bướu',
          tab: 'master',
          children: []
        },
        {
          name: 'Xét Nghiệm/ CLS (3)',
          tab: 'CLS',
          children: []
        },
        {
          name: 'Chẩn đoán hình ảnh',
          tab: 'ChanDoanHinhAnh',
          children: []
        },
        {
          name: 'Biên Bản Hội Chẩn (1)',
          tab: 'BienBanHoiChan',
          children: []
        },
        {
          name: 'Phiếu sơ kết',
          tab: 'PhieuSoKet',
          children: []
        },
        {
          name: 'Tóm tắt thông qua mổ',
          tab: 'TTTQM',
          children: []
        },
        {
          name: 'Phiếu phẫu thuật/ thủ thuật',
          tab: 'PhieuPhauThuat',
          children: []
        },
        {
          name: 'Phiếu khám gây mê trước mổ',
          tab: 'PhieuKhamGayMeTruocMo',
          children: []
        },
        {
          name: 'Phiếu gây mê hồi sức',
          tab: 'PhieuGayMeHoiSuc',
          children: []
        },
        {
          name: 'Phiếu Điều trị (2)',
          tab: 'PhieuDieuTri',
          children: []
        },
        {
          name: 'Phiếu chăm sóc',
          tab: 'PhieuChamSoc',
          children: []
        },
        {
          name: 'Phiếu theo dõi chức năng sống',
          tab: 'Vitalsign',
          children: []
        },
        {
          name: 'Đánh giá dinh dưỡng',
          tab: 'DanhGiaDinhDuong',
          children: []
        },
        {
          name: 'Phiếu công khai thuốc và dịch vụ y tế',
          tab: 'DichVuYTe',
          children: []
        },
        {
          name: 'Biểu mẫu hành chính',
          tab: 'PhieuHanhChinh',
          children: [
            {
              name: 'Chuyển tuyến',
              tab: 16
            },
            {
              name: 'Giấy hẹn khám lại',
              tab: 17
            },
            {
              name: 'Giấy chứng nhân nghỉ việc hưởng BHXH',
              tab: 18
            },
            {
              name: 'Giấy ra viện',
              tab: 19
            },
            {
              name: 'Giấy cam kết',
              tab: 20
            }
          ]
        }
      ],
      medicalRecordMain: null,
      idMedicalRecords: null,
      medical_record_code: null,
      treat_doctor: null,
      getBADT: [],
      mounting: false,
      tablist
    }
  },
  created () {
    if (
      _.isEmpty(this.$featureList) &&
      _.isEmpty(this.$featureWsList) &&
      _.isEmpty(this.$featureOrgList)
    ) {
      this.mounting = true
      setTimeout(() => {
        this.mounting = false
      }, 5000)
    } else {
      this.mounting = false
    }
  },
  computed: {
    hasBadt () {
      return (
        (this.$featureList && this.$featureList['badt']) ||
        (this.$appMode === APP_MODE.workspace
          ? this.$featureWsList && this.$featureWsList['badt']
          : this.$appMode === APP_MODE.organization
            ? this.$featureOrgList && this.$featureOrgList['badt']
            : false) ||
        false
      )
    }
  },
  watch: {
    $featureList (list) {
      if (!_.isEmpty(list)) {
        this.mounting = false
      }
    },
    $featureWsList (list) {
      if (!_.isEmpty(list)) {
        this.mounting = false
      }
    },
    $featureOrgList (list) {
      if (!_.isEmpty(list)) {
        this.mounting = false
      }
    }
  },
  mounted () {
    this.getDetailBADT()
  },
  methods: {
    backListBADT () {
      this.$router.go(-1)
    },
    async getDetailBADT () {
      this.idMedicalRecords = this.$route.params.id
      if (!this.idMedicalRecords) {
        return
      }
      const res = await this.$rf
        .getRequest('DoctorRequest')
        .getDetailBADT(this.idMedicalRecords)
      this.getBADT = res.data
      const medicalRecordMasterResponse = await this.getMedicalRecordMaster(
        res.data?.medical_record_master?.id
      )
      this.medicalRecordMain = medicalRecordMasterResponse || {}
      this.medical_record_code = res.data?.medical_record_code
      this.treat_doctor = res.data?.treat_doctor
      const person = {
        ...res.data.person,
        medical_record_name: res.data.medical_record_name,
        treat_doctor: res.data.treat_doctor_name,
        main_doctor: res.data.main_doctor_name,
        status: this.getBADT && this.getBADT.status,
        ref_id: res.data.ref_id,
        person_ref_id: res.data.person_ref_id
      }
      if (this.medicalRecordMain && this.medicalRecordMain.admission_room) {
        person.admission_room = this.medicalRecordMain.admission_room
      }
      if (this.medicalRecordMain && this.medicalRecordMain.admission_bed) {
        person.admission_bed = this.medicalRecordMain.admission_bed
      }
      this.$store.commit('setPerson', { person })
      this.$store.commit('setMedicalRecordMain', {
        medicalRecordMain: this.medicalRecordMain
      })
      this.$store.commit('setEmrData', {
        emrData: this.getBADT
      })
    },
    async getMedicalRecordMaster (medicalRecordMasterID) {
      if (!medicalRecordMasterID) return

      try {
        const response = await this.$rf
          .getRequest('DoctorRequest')
          .getDetailMedicalRecordMaster(medicalRecordMasterID)

        return response.data
      } catch (error) {
        console.log(error)
      }
    }
  }
}
</script>
