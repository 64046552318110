var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"bg-white py-2 rounded-xl",attrs:{"id":"ElectronicMedicalRecords"}},[_c('div',[_c('div',{staticClass:"flex h-full"},[_c('MenuTreeView'),_c('div',{staticClass:"w-full h-detail px-3 overflow-y-scroll relative"},[_c('TreatmentCard',{directives:[{name:"show",rawName:"v-show",value:(_vm.tab === 'master'),expression:"tab === 'master'"}],attrs:{"medicalRecordMain":_vm.medicalRecordMain,"medical_record_code":_vm.medical_record_code,"treat_doctor":_vm.treat_doctor,"getBADT":_vm.getBADT},on:{"refresh":_vm.getDetailBADT}}),(
            !_vm.hasVimesSync &&
            (_vm.tab === 'CLS' || _vm.tab === 'ChanDoanHinhAnh') &&
            _vm.showTab
          )?_c('EmrIndicationList',{attrs:{"mr_id":_vm.mr_id,"indType":_vm.tab === 'CLS' ? 1 : 2}}):_vm._e(),(
            _vm.hasVimesSync &&
            (_vm.tab === 'CLS' || _vm.tab === 'ChanDoanHinhAnh') &&
            _vm.showTab
          )?_c('EmrIndicationListVimes',{attrs:{"mr_id":_vm.mr_id,"indType":_vm.tab === 'CLS' ? 1 : 2}}):_vm._e(),(_vm.tab === 'BienBanHoiChan')?_c('DanhSachBienBanHoiChan',{attrs:{"mr_id":_vm.mr_id}}):_vm._e(),(_vm.tab === 'TTTQM')?_c('FormTTTQM',{attrs:{"mr_id":_vm.mr_id}}):_vm._e(),(_vm.tab === 'PhieuSoKet')?_c('PhieuSoKetList'):_vm._e(),(_vm.tab === 'PhieuDieuTri')?_c('TreatmentList'):_vm._e(),(_vm.tab === 'PhieuChamSoc')?_c('PhieuChamSocList'):_vm._e(),(_vm.tab === 'PhieuYLenhTruyen')?_c('PhieuYLenhTruyenList'):_vm._e(),(_vm.tab === 'DanhGiaDinhDuong')?_c('PhieuDanhGiaDinhDuongList'):_vm._e(),(_vm.tab === 'Vitalsign')?_c('EmrVitalsignList',{attrs:{"mr_id":_vm.mr_id}}):_vm._e(),(_vm.tab === 'PhieuPhauThuat')?_c('PhieuPhauThuatList'):_vm._e(),(_vm.tab === 'PhieuKhamGayMeTruocMo')?_c('PhieuKhamGayMeTruocMoList'):_vm._e(),(_vm.tab === 'PhieuGayMeHoiSuc')?_c('PhieuGayMeHoiSucList'):_vm._e(),(_vm.tab === 'DichVuYTe')?_c('DichVuYTeList'):_vm._e(),(_vm.tab === 'ChuyenTuyen')?_c('ChuyenTuyen'):_vm._e(),(_vm.tab === 'GiayHenKhamLai')?_c('GiayHenKhamLai'):_vm._e(),(_vm.tab === 'GiayChungNhan')?_c('GiayChungNhan'):_vm._e(),(_vm.tab === 'BMHC' && _vm.forDisease)?_c('ListBMHCForDisease'):_vm._e(),(_vm.tab === 'GiayXacNhanDieuTri')?_c('GiayXacNhanDieuTri'):_vm._e(),(_vm.tab === 'GiayChungNhanPhauThuat')?_c('GiayXacNhanDieuTri'):_vm._e(),(_vm.tab === 'GiayDeNghiThanhToan')?_c('GiayDeNghiThanhToan'):_vm._e(),(_vm.tab === 'BMHC' && !_vm.forDisease)?_c('ListBMHC'):_vm._e()],1)],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }