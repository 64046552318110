<template>
  <div>
    <div class="Flipped-state-wraper">
      <div
        class="loading-screen"
        v-if="loading"
      >
        <div
          class="spinner-border"
          role="status"
        >
          <span class="sr-only">Loading...</span>
        </div>
      </div>
      <!-- IndicationsState -->
      <div class="state-wraper">
        <div class="checkpoint-item">
          <IndicationsState />
        </div>
      </div>
      <!-- IndicationsState -->
    </div>
  </div>
</template>

<script>
import IndicationsState from '../Indications'
export default {
  name: 'MapView',
  components: { IndicationsState },
  data () {
    return {
      emr_states: [],
      loading: false
    }
  },
  mounted () {
  },
  methods: {
  }
}
</script>
  
<style lang="scss" scoped>
:v-deep {
  .Flipped-state-wraper {
    position: relative;
    width: 100%;
    overflow: auto;
    min-height: 50vh;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
  }
  .state-wraper {
    padding-top: 8px;
    width: 100%;
    display: flex;
  }

  .checkpoint-item {
    min-width: 330px;
    max-width: 330px;
    margin-right: 30px;
  }

  ::-webkit-scrollbar {
    height: 6px;
  }

  ::-webkit-scrollbar-track {
    background: #f1f1f1;
  }
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: rgb(172, 172, 172);
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
  .loading-screen {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
  }
}
</style>